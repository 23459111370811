import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {categoryStore, IState} from '../CategoryStore'
import {observer} from 'mobx-react-lite'
import {numberUtil} from '../../../common/utils/NumberUtil'
import UploadImg from '../../../common/components/UploadImg'

const EditCategory = observer(() => {

  return (
    <div className='modal fade' id='edit_cate' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-1000px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Cập nhập danh mục</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' id='close_edit' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            <form noValidate className='form'>
              <div className='mb-5'>
                <label className='form-label w-100'>Ảnh</label>
                <UploadImg src={categoryStore.dataRequest.image} changeImg={categoryStore.changeImg}/>
              </div>
              <div className='mb-5'>
                <label className='form-label'>Tên danh mục</label>
                <input type='text' className='form-control' value={categoryStore.dataRequest.name}
                       onChange={(e: any) => categoryStore.dataRequest.name = e.currentTarget.value}
                       placeholder='Nhập tên' />
              </div>
              <div className='mb-5'>
                <label className='form-label'>Vị trí</label>
                <input type='text' className='form-control' value={categoryStore.dataRequest.sort}
                       onChange={(e: any) => categoryStore.dataRequest.sort = numberUtil.regexNumber(e)}
                       placeholder='Nhập vị trí' />
              </div>
              <div className='mb-5'>
                <label className='form-label'>Danh mục cha</label>
                <select className='form-select' value={categoryStore.dataRequest.parentId} onChange={(e: any) => categoryStore.dataRequest.parentId = e.currentTarget.value}>
                  <option>Chọn danh mục</option>
                  {categoryStore.listCateParent && categoryStore.listCateParent.map((item, i) => {
                    return (
                      <option value={item.id} key={i} selected={categoryStore.dataRequest.parentId == item.id && true}>{item.name}</option>
                    )
                  })}
                </select>
              </div>
              <div className='mb-5'>
                <label className='form-label'>Trạng thái</label>
                <select className='form-select' value={categoryStore.dataRequest.state} onChange={(e: any) => categoryStore.dataRequest.state = e.currentTarget.value}>
                  <option>Chọn trạng thái</option>
                  <option value={IState.INACTIVE} selected={categoryStore.dataRequest.state == IState.INACTIVE && true}>Ẩn</option>
                  <option value={IState.ACTIVE} selected={categoryStore.dataRequest.state == IState.ACTIVE && true}>Hiện</option>
                  <option value={IState.DELETED} selected={categoryStore.dataRequest.state == IState.DELETED && true}>Xóa</option>
                </select>
              </div>
            </form>
          </div>

          <div className='modal-footer d-flex justify-content-end py-6 px-9'>
            <button type='reset' className='btn btn-light btn-active-light-primary me-2'
                    data-bs-dismiss='modal'>Hủy
            </button>
            <button type='submit' className='btn btn-primary' onClick={() => categoryStore.update()}
                    disabled={categoryStore.isLoadingBt}>
              {categoryStore.isLoadingBt ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              ) : 'Lưu'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )

})

export default EditCategory