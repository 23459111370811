import {IApiResponse, getRequest, postRequest, putRequest, deleteRequest} from '../../common/helpers/RequestHelper'
import {productStore} from './ProductStore'



class ProductService {

  public getProducts(): Promise<IApiResponse>{
    return getRequest(`/v1/products?page=${productStore.page}&size=10${productStore.shopId ? `&collection=FILTER&shopId=${productStore.shopId}` : ''}`)
  }

  public getDetailProduct(id: any): Promise<IApiResponse>{
    return getRequest(`/v1/products/${id}`)
  }

  public addProduct(shopId: any, data: any ): Promise<IApiResponse>{
    return postRequest(`/v1/cms/products/shop/${shopId}`, data)
  }

  public updateProduct(productId: any, shopId: any, data: any): Promise<IApiResponse>{
    return putRequest(`/v1/cms/products/${productId}/shop/${shopId}`, data)
  }

  public deleteProduct(productId: any): Promise<IApiResponse>{
    return deleteRequest(`/v1/cms/products/${productId}`, {productId})
  }

  public getUnit(): Promise<IApiResponse>{
    return getRequest(`/v1/resource/unit`)
  }


}

export const productService = new ProductService()