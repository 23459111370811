import React, {useState} from 'react'
import {getRequest, IApiResponse} from '../helpers/RequestHelper'
import axios from 'axios'
import {observer, useObserver} from 'mobx-react-lite'

interface IProps {
  src: string,
  changeImg: any
}

function generateUpload(fileName: any): Promise<IApiResponse> {
  return getRequest(`/v1/storage/generate-upload-url?fileName=${fileName}`)
}


const UploadImg = observer(({src, changeImg}: IProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const onChangeFileThumb = async (e: any) => {
    e.preventDefault()
    const reader = new FileReader();
    let file = e.target.files[0]
    await getGenerateUrl(file)
    reader.readAsDataURL(file)
  }


  const getGenerateUrl = async (file: any) => {
    const result = await generateUpload(file.name)
    if (result.status == 200) {
      await sendUploadThumb(file, result.body)
    }
  }


  const sendUploadThumb = async (file: any, data: any) => {

    const uploadImg = axios.create({
      baseURL: data.uploadUrl,
      method: 'PUT',
      headers: {
        'Content-Type': file.type
      }
    })

    setIsLoading(true)
    let result = await uploadImg.put(data.uploadUrl, file)
    if (result.status == 200) {
      changeImg(data.imageUrl)
      setIsLoading(false)
    }

  }

  return useObserver(() => (
    <div className='upLoadImage w-100 d-flex'>
      {src ? <img src={src} alt='' /> :
        <div className='no_img d-flex align-items-center justify-content-center'><i className='far fa-image' /></div>}
      <button className='btn change-avatar'><span>{isLoading ? 'Loading ...' : 'Upload'}</span>
        <input accept='image/jpeg, image/jpg, image/png' type='file'
               onChange={async (e: any) => await onChangeFileThumb(e)} />
      </button>
    </div>
  ))
})

export default UploadImg