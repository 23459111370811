import {makeAutoObservable} from 'mobx'
import $ from "jquery"
import {productService} from './ProductService'
import HttpStatusCode from '../../common/constants/HttpErrorCode'

class ProductStore {
  id: any = ''
  shopId: any = ''
  page: any = 0
  listState: string[] = ['PUBLIC', 'DRAFT', 'PENDING', 'REJECT', 'DELETED', 'REPORTED']
  totalPage: any = 0
  listUnit: any[] = []
  listProduct: any[] = []
  dataRequest: any = {
    name: '',
    shopId: '',
    categoryId: '',
    price: '',
    salePrice: '',
    quantity: '',
    unitId: '',
    originSourceLink: '',
    shopCategoryId: '',
    description: '',
    packingSize: ['', '', ''],
    weight: '',
    state: '',
    videos: [],
    sku: '',
    images: [],
    wholeSales: [],
    variants: []
  }
  isLoading: boolean = false
  isLoadingBt: boolean = false


  constructor() {
    makeAutoObservable(this)
  }

  clearForm(){
    this.dataRequest = {
      name: '',
      shopId: '',
      categoryId: '',
      price: '',
      salePrice: '',
      quantity: '',
      unitId: '',
      originSourceLink: "",
      shopCategoryId: '',
      description: "",
      packingSize: ['', '', ''],
      weight: '',
      state: "",
      videos: [],
      sku: "",
      images: [],
      wholeSales: [
      ],
      variants: [
      ]
    }
  }


  async getProducts(){
    this.isLoading = true
    const result = await productService.getProducts()
    this.isLoading = false

    if(result.status === HttpStatusCode.OK){
      this.listProduct = result.body.data;
      this.totalPage = result.body.metadata.totalPage
    }

  }

  async getProductDetail(id: any){
    const result = await productService.getDetailProduct(id)

    if(result.status === HttpStatusCode.OK){
      this.dataRequest = result.body;
      if(!productStore.dataRequest.packingSize || productStore.dataRequest.packingSize.length < 0){
        productStore.dataRequest.packingSize = ['', '', '']
      }
    }
  }

  async getUnit(){
    const result = await productService.getUnit()
    if(result.status === HttpStatusCode.OK){
      this.listUnit = result.body
    }
  }

  async uploadImg(value: any) {
    productStore.dataRequest.images.push(value)
  }

  async removeImg(i: any) {
    productStore.dataRequest.images.splice(i, 1)
  }


  async addProduct(){
    let {name, categoryId, price, salePrice, quantity, unitId, originSourceLink, description, sku, weight, packingSize, images} = this.dataRequest
    let data: any = {
      name: name,
      categoryId: categoryId,
      price: price,
      salePrice: salePrice,
      quantity: quantity,
      unitId: unitId,
      originSourceLink: originSourceLink,
      shopCategoryId: '',
      description: description,
      packingSize: packingSize,
      weight: weight,
      videos: [
        "https://salt.tikicdn.com/cache/400x400/ts/product/9f/87/5a/c2526097eded50f8304df287ea53cd3a.jpg",
        "https://salt.tikicdn.com/cache/400x400/ts/product/9f/87/5a/c2526097eded50f8304df287ea53cd3a.jpg",
        "https://salt.tikicdn.com/cache/400x400/ts/product/9f/87/5a/c2526097eded50f8304df287ea53cd3a.jpg"
      ],
      sku: sku,
      images: images,
      wholeSales: [],
      variants: []
    }
    this.isLoadingBt = true
    const result = await productService.addProduct(this.shopId, data)
    this.isLoadingBt = false

    if(result.status === HttpStatusCode.OK){
      alert('Thêm sản phẩm thành công')
      this.clearForm()
    }else {

    }

  }

  async editProduct(){
    let {id, shopId, name, categoryId, price, salePrice, quantity, unitId, originSourceLink, description, sku, weight, packingSize, images} = this.dataRequest
    let data: any = {
      name: name,
      categoryId: categoryId,
      price: price,
      salePrice: salePrice,
      quantity: quantity,
      unitId: unitId,
      originSourceLink: originSourceLink,
      shopCategoryId: '',
      description: description,
      packingSize: packingSize,
      weight: weight,
      videos: [
        "https://salt.tikicdn.com/cache/400x400/ts/product/9f/87/5a/c2526097eded50f8304df287ea53cd3a.jpg",
        "https://salt.tikicdn.com/cache/400x400/ts/product/9f/87/5a/c2526097eded50f8304df287ea53cd3a.jpg",
        "https://salt.tikicdn.com/cache/400x400/ts/product/9f/87/5a/c2526097eded50f8304df287ea53cd3a.jpg"
      ],
      sku: sku,
      images: images,
      wholeSales: [],
      variants: []
    }
    this.isLoadingBt = true
    const result = await productService.updateProduct(id, shopId, data)
    this.isLoadingBt = false

    if(result.status === HttpStatusCode.OK){
      alert('Sửa sản phẩm thành công')
      window.location.href = '/products'
      this.clearForm()
    }else {

    }

  }

  async delete(){
    this.isLoadingBt = true
    const result = await productService.deleteProduct(this.id)
    this.isLoadingBt = false

    if(result.status === HttpStatusCode.OK){
      $('#close_delete').trigger('click')
      await this.getProducts()
    }else {

    }
  }

}

export const productStore = new ProductStore()