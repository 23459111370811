/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        // icon='/media/icons/duotune/art/art002.svg'
        title='Thống kê'
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Config</span>
        </div>
      </div>
      <AsideMenuItem
        to='/banner'
        title='Banner'
        hasBullet={true}
      />
      <AsideMenuItem
        to='/menu'
        title='Menu'
        hasBullet={true}
      />

      <AsideMenuItem
        to='/footer'
        title='Footer'
        hasBullet={true}
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Quản lý sản phẩm</span>
        </div>
      </div>
      <AsideMenuItem
        to='/products'
        // icon='/media/icons/duotune/general/gen019.svg'
        title='Sản phẩm'
        fontIcon='bi-layers'
        hasBullet={true}
      />
      <AsideMenuItem
        to='/categories'
        // icon='/media/icons/duotune/general/gen019.svg'
        title='Danh mục'
        fontIcon='bi-layers'
        hasBullet={true}
      />


      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Quản lý cửa hàng</span>
        </div>
      </div>
      <AsideMenuItem
        to='/shops'
        // icon='/media/icons/duotune/general/gen019.svg'
        title='Cửa hàng'
        fontIcon='bi-layers' hasBullet={true}
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Quản lý đơn hàng</span>
        </div>
      </div>
      <AsideMenuItem
        to='/order'
        // icon='/media/icons/duotune/general/gen019.svg'
        title='Đơn hàng'
        fontIcon='bi-layers' hasBullet={true}
      />

      {/*<AsideMenuItemWithSub*/}
      {/*  to='/pages'*/}
      {/*  title='Pages'*/}
      {/*  fontIcon='bi-archive'*/}
      {/*  icon='/media/icons/duotune/general/gen022.svg'*/}
      {/*>*/}
      {/*  <AsideMenuItem*/}
      {/*    to='/profile'*/}
      {/*    title='Connections'*/}
      {/*    hasBullet={true}*/}
      {/*  />*/}
      {/*</AsideMenuItemWithSub>*/}

      {/*<AsideMenuItemWithSub to='/profile' title='Profile' hasBullet={true}>*/}
      {/*  <AsideMenuItem*/}
      {/*    to='/profile/connections'*/}
      {/*    title='Connections'*/}
      {/*    hasBullet={true}*/}
      {/*  />*/}
      {/*</AsideMenuItemWithSub>*/}

    </>
  )
}
