import {getRequest, postRequest, deleteRequest, IApiResponse} from '../../../common/helpers/RequestHelper'

class MenuService {

  public getMenu(): Promise<IApiResponse>{
    return getRequest(`/v1/config/menu`)
  }

  public addOrUpdateMenu(data: any): Promise<IApiResponse>{
    return postRequest(`/v1/config/menu`, data)
  }

  public deleteMenu(id: any): Promise<IApiResponse>{
    return deleteRequest(`/v1/config/menu/${id}`, {id})
  }

}

export const menuService = new MenuService()

