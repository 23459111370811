import React, {useEffect} from 'react'
import Loading from '../../../common/components/Loading'
import NoContent from '../../../common/components/NoContent'
import {observer} from 'mobx-react-lite'
import {menuStore} from './MenuStore'
import DeleteMenu from './components/DeleteMenu'
import AddOrUpdate from './components/AddOrUpdate'

const MenuWrapper = observer(() => {

  useEffect(() => {
    menuStore.getMenu()
  }, [])

  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-10'>
        <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Danh sách menu</h1>
        <a href='#' className='btn btn-sm btn-primary' onClick={() => menuStore.clearForm()} data-bs-toggle='modal' data-bs-target='#add_or_update'>Tạo mới</a>
      </div>
      {menuStore.isLoading ? <Loading/> : <div className='table-responsive'>
        {menuStore.listMenu && menuStore.listMenu.length > 0 ? <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
          <thead>
          <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
            <th>ID</th>
            <th>Vị trí</th>
            <th>Ảnh</th>
            <th>Tiêu đề</th>
            <th>Liên kết đến</th>
            <th/>
          </tr>
          </thead>
          <tbody>
          {menuStore.listMenu.map((item: any, i: number) => {
            return (
              <tr key={i}>
                <td>{item.id}</td>
                <td>{item.sort}</td>
                <td>
                  <div className="symbol symbol-40px symbol-md-40px">
                    <img src={item.image} alt=""/>
                  </div>
                </td>
                <td>{item.title}</td>
                <td>{item.linkTo}</td>
                <td width="10%">
                  <a href="#" className="btn btn-icon btn-warning btn-sm" onClick={() => menuStore.getMenuDetail(item)} data-bs-toggle='modal' data-bs-target='#add_or_update'><i className="fas fa-pen"/></a>
                  <span className="p-2"/>
                  <a href="#" className="btn btn-icon btn-danger btn-sm" data-bs-toggle='modal' data-bs-target='#delete_menu' onClick={() => menuStore.id = item.id}><i className="fas fa-trash-alt"/></a>
                </td>
              </tr>
            )
          })}
          </tbody>
        </table> : <NoContent/>}
        <AddOrUpdate/>
        <DeleteMenu/>
      </div>}

    </>
  )
})

export default MenuWrapper