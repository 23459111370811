import {useState} from 'react'
import {getRequest, IApiResponse} from '../helpers/RequestHelper'
import axios from 'axios'
import {observer} from 'mobx-react-lite'

interface IProps {
  listImg: string[],
  changeImg: any,
  removeImg: any
}

function generateUpload(fileName: any): Promise<IApiResponse> {
  return getRequest(`/v1/storage/generate-upload-url?fileName=${fileName}`)
}

const UploadMultiImg = observer(({listImg, changeImg, removeImg}: IProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const onChangeFileThumb = async (e: any) => {
    e.preventDefault()
    const reader = new FileReader();
    let file = e.target.files[0]
    await getGenerateUrl(file)
    reader.readAsDataURL(file)
  }

  const getGenerateUrl = async (file: any) => {
    const result = await generateUpload(file.name)
    if (result.status == 200) {
      await sendUploadThumb(file, result.body)
    }
  }


  const sendUploadThumb = async (file: any, data: any) => {

    const uploadImg = axios.create({
      baseURL: data.uploadUrl,
      method: 'PUT',
      headers: {
        'Content-Type': file.type
      }
    })

    setIsLoading(true)
    let result = await uploadImg.put(data.uploadUrl, file)
    if (result.status == 200) {
      changeImg(data.imageUrl)
      setIsLoading(false)
    }

  }


  return (
    <div className='upLoadImage upLoadMultiImage w-100 d-flex'>
      {listImg && listImg.length > 0 ? <div className='d-flex align-items-center'>
        {listImg.map((item: any, i: number) => {
          return <div className="position-relative" style={{marginRight: 16}}>
            <img src={item} key={i} alt='' />
            <span onClick={() => removeImg(i)} style={{right: -9, top: -9}} className="btn rm_img position-absolute btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                  data-kt-image-input-action="remove" data-bs-toggle="tooltip" title=""
                  data-bs-original-title="Remove Image">
								<i className="bi bi-x fs-2"/>
						</span>
          </div>
        })}
      </div> : <div className='no_img d-flex align-items-center justify-content-center'><i className='far fa-image' /></div>}
      {listImg && listImg.length < 3 && <button className='btn change-avatar'><span>{isLoading ? 'Loading ...' : 'Upload'}</span>
        <input accept='image/jpeg, image/jpg, image/png' type='file'
               onChange={async (e: any) => await onChangeFileThumb(e)} />
      </button>}
    </div>
  )
})

export default UploadMultiImg