import React  from "react";
import { footerStore } from "../FooterStore";
import { KTSVG } from "../../../../../_metronic/helpers";
import { observer } from "mobx-react-lite";
import EditorComponent from "../../../../common/components/Editor";

const EditFooter = observer(() => {


  return (
    <div className='modal fade' id='edit_config_footer' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-1000px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Edit footer</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' id='close_edit' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body py-lg-10 px-lg-10'>
            <div className='mb-5'>
              <label className='form-label'>Tiêu đề</label>
              <input type='text' className='form-control' value={footerStore.dataRequest.title} onChange={(e: any) => footerStore.dataRequest.title = e.currentTarget.value} placeholder='Nhập tiêu đề' />
            </div>
            <div className='mb-5'>
              <label className='form-label'>Nội dung</label>
              {/*<EditorComponent/>*/}
              <textarea className="form-control" rows={6} value={footerStore.dataRequest.content} onChange={(e: any) => footerStore.dataRequest.content = e.currentTarget.value}/>
            </div>
          </div>

          <div className='modal-footer d-flex justify-content-end py-6 px-9'>
            <button type='reset' className='btn btn-light btn-active-light-primary me-2' data-bs-dismiss='modal'>Hủy
            </button>
            <button type='submit' className='btn btn-primary' onClick={() => footerStore.update()}
                    disabled={footerStore.isLoadingBt}>
              {footerStore.isLoadingBt ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              ) : 'Lưu'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
})

export default EditFooter;