import {makeAutoObservable} from 'mobx'
import $ from "jquery"
import HttpStatusCode from '../../../common/constants/HttpErrorCode'
import {footerService} from './FooterService'
import axios from "axios";



class FooterStore {
  id: any = ''
  value: any = ''
  timer: any = null;
  isLoading: boolean = false
  isLoadingBt: boolean = false
  listFooter: any[] = []
  dataRequest: any = {
    id: '',
    title: '',
    content: ''
  }

  constructor() {
    makeAutoObservable(this)
  }


   handleEditorChange = (e: any) => {
    footerStore.value = e.target.innerHTML.toString();
    clearTimeout(this.timer);
    this.timer = setTimeout( () => {
      footerStore.dataRequest.content = footerStore.value;
    }, 30000);
  }

  clearForm(){
    this.dataRequest = {
      id: '',
      title: '',
      content: ''
    }
  }


  async getFooter(){
    this.isLoading = true;
    const result = await footerService.getFooter()
    this.isLoading = false;
    if(result.status === HttpStatusCode.OK){
      this.listFooter = result.body;
    }
  }
  
  async getDetail(id: any) {
    const result = await footerService.getDetail(id);
    if (result.status === HttpStatusCode.OK) {
      this.dataRequest = result.body;
    }
  }

  async add() {
    if (this.value) {
      this.dataRequest.content = footerStore.value
    }
    let {title, content} = this.dataRequest

    let data: any = {
      title: title,
      content: content,
      footerCateId: 1,
    }

    if(!title){
      alert('Tiêu đề không được để trống')
      return
    }

    if(!content){
      alert('Nội dung không được để trống')
      return
    }


    this.isLoadingBt = true;
    const result = await footerService.addFooter(data);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      $('#close_add').trigger('click')
      this.clearForm()
      await this.getFooter()
    }
  }


  async image_upload_handler(blobInfo: any, failure: any) {
    footerStore.dataRequest.content = footerStore.value;
    let formData: any;

    const result = await footerService.generateUpload(blobInfo.filename())
    if (result.status == 200) {
      await footerStore.sendUploadThumb(blobInfo, result.body)
    }

    $('.tox-button--naked').trigger('click');
    failure('Upload image successfull');

    formData = new FormData();
    formData.append('image_url', blobInfo.blob(), blobInfo.filename());

  };


   sendUploadThumb = async (blobInfo: any, data: any) => {
    const uploadImg = axios.create({
      baseURL: data.uploadUrl,
      method: 'PUT',
      headers: {
        'Content-Type': blobInfo.blob().type
      }
    })
    let result = await uploadImg.put(data.uploadUrl, blobInfo.blob())
    if (result.status == 200) {
      footerStore.dataRequest.content += `<img src=${data.imageUrl} alt="">`;
      footerStore.value = footerStore.dataRequest.content;
    }

  }

  async update() {
    if (this.value) {
      this.dataRequest.content = footerStore.value
    }

    let {id, title, content} = this.dataRequest

    let data: any = {
      id: id,
      title: title,
      content: content,
      footerCateId: 1,
    }

    this.isLoadingBt = true;
    const result = await footerService.updateFooter(id, data);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      $('#close_edit').trigger('click')
      this.listFooter.map((item) => {
        if (item.id === id) {
          item.title = title
          item.content = content
        }
      })
      this.clearForm()
    }
  }

  async delete() {
    this.isLoadingBt = true;
    const result = await footerService.deleteFooter(this.id);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      this.listFooter.map((item, i) => {
        if (item.id === this.id) {
          this.listFooter.splice(i, 1)
        }
      })

      $('#close_delete').trigger('click')
    }
  }

}

export const footerStore = new FooterStore();