
import {getRequest, IApiResponse} from '../../common/helpers/RequestHelper'

class ProfileService {

  public getProfile(): Promise<IApiResponse>{
    return getRequest(`/v1/users/me`)
  }

}

export const profileService = new ProfileService();