import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {shopStore} from '../ShopStore'
import UploadImg from '../../../common/components/UploadImg'
import UploadMultiImg from '../../../common/components/UploadMultiImg'
import {useLocation} from 'react-router'
import AddFactory from './AddFactory'
import NoContent from '../../../common/components/NoContent'

const EditShop = observer(() => {

  const query = useLocation()

  let id = query.pathname.split('.')[1]

  useEffect(() => {
    shopStore.getShopDetail(id)
    shopStore.getProvinces()
  }, [])


  return (
    <div className='edit_shop'>
      <div className='d-flex align-items-center justify-content-between mb-10'>
        <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Cập nhập cửa hàng</h1>
      </div>

      <div className='content'>
        <div className='row'>
          <div className='col-6'>
            <div className="mb-5">
              <label className="form-label">Ảnh</label>
              <UploadImg src={shopStore.dataRequest.avatar} changeImg={shopStore.changeAvatar}/>
            </div>
            <div className="mb-5">
              <label className="form-label">Tên cửa hàng</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.name} onChange={(e: any) => shopStore.dataRequest.name = e.currentTarget.value} placeholder="Nhập tên cửa hàng"/>
            </div>
            <div className="mb-5">
              <label className="form-label">Tỉnh/Thành phố</label>
              <select className='form-select' value={shopStore.dataRequest.provinceId} onChange={(e: any) => shopStore.changeProvince(e.currentTarget.value)}>
                <option>Chọn</option>
                {shopStore.listProvince && shopStore.listProvince.map((item, i) => {
                  return (
                    <option value={item.id} key={i} selected={shopStore.dataRequest.provinceId == item.id && true}>{item.name}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='col-6'>
            <div className="mb-5">
              <label className="form-label">Ảnh bìa</label>
              <UploadImg src={shopStore.dataRequest.banner} changeImg={shopStore.changeBanner}/>
            </div>
            <div className="mb-5">
              <label className="form-label">Trang web</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.website} onChange={(e: any) => shopStore.dataRequest.website = e.currentTarget.value} placeholder="Nhập link trang mạng"/>
            </div>
            <div className="mb-5">
              <label className="form-label">Quận/Huyện</label>
              <select className='form-select' value={shopStore.dataRequest.districtId} onChange={(e: any) => shopStore.dataRequest.districtId = e.currentTarget.value}>
                <option>Chọn</option>
                {shopStore.listDistrict && shopStore.listDistrict.map((item, i) => {
                  return (
                    <option value={item.id} key={i} selected={shopStore.dataRequest.districtId == item.id && true}>{item.name}</option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="w-100 pb-4 border-bottom mt-8 mb-8">
          <strong><h4>Hồ sơ doanh nghiệp</h4></strong>
        </div>
        <div className="mb-5">
          <label className="form-label">Thông tin cơ bản</label>
          <textarea className="form-control" rows={4} value={shopStore.dataRequest.shopCompany.description} onChange={(e: any) => shopStore.dataRequest.shopCompany.description = e.currentTarget.value} placeholder="Nhập thông tin cơ bản"/>
        </div>
        <div className="w-100 pb-4 border-bottom mt-8 mb-8">
          <strong><h4>Thông tin đơn vị</h4></strong>
        </div>
        <div className='row'>
          <div className='col-6'>
            <div className="mb-5">
              <label className="form-label">Tên đại diện</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.representer} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.representer = e.currentTarget.value} placeholder="Nhập tên đại diện"/>
            </div>
            <div className="mb-5">
              <label className="form-label">Số điện thoại</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.phone} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.phone = e.currentTarget.value} placeholder="Nhập số điện thoại"/>
            </div>

            <div className="mb-5">
              <label className="form-label">Email</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.email} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.email = e.currentTarget.value} placeholder="Nhập email"/>
            </div>

            <div className="mb-5">
              <label className="form-label">Địa chỉ</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.address} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.address = e.currentTarget.value} placeholder="Nhập địa chỉ"/>
            </div>

            <div className="mb-5">
              <label className="form-label">Trang web</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.website} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.website = e.currentTarget.value} placeholder="Nhập link trang mạng"/>
            </div>
          </div>
          <div className='col-6'>
            <div className="mb-5">
              <label className="form-label">Loại hình</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.business} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.business = e.currentTarget.value} placeholder="Nhập loại hình"/>
            </div>
            <div className="mb-5">
              <label className="form-label">Sản phẩm chính</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.mainProduct} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.mainProduct = e.currentTarget.value} placeholder="Nhập sản phẩm chính"/>
            </div>
            <div className="mb-5">
              <label className="form-label">Tổng doanh thu</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.revenue} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.revenue = e.currentTarget.value} placeholder="Nhập tổng doanh thu"/>
            </div>
            <div className="mb-5">
              <label className="form-label">Tổng nhân viên</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.numberOfEmployees} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.numberOfEmployees = e.currentTarget.value} placeholder="Nhập tổng nhân viên"/>
            </div>
            <div className="mb-5">
              <label className="form-label">Năm thành lập</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.unitInformation.foundedYear} onChange={(e: any) => shopStore.dataRequest.shopCompany.unitInformation.foundedYear = e.currentTarget.value} placeholder="Nhập năm thành lập"/>
            </div>
          </div>
        </div>

        <div className="w-100 pb-4 border-bottom mt-8 mb-8">
          <strong><h4>Năng lực sản xuất</h4></strong>
        </div>
        <div className="mb-5">
          <label className="form-label">Kích thước nhà máy</label>
          <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.productionCapacity.factoryArea} onChange={(e: any) => shopStore.dataRequest.shopCompany.productionCapacity.factoryArea = e.currentTarget.value} placeholder="Nhập kích thước nhà máy"/>
        </div>
        <div className="w-100 pb-4 border-bottom mt-8 mb-8">
          <strong><h4>Giấy chứng nhận</h4></strong>
        </div>
        <UploadMultiImg listImg={shopStore.dataRequest.shopCompany.certification} changeImg={shopStore.uploadImgCertification} removeImg={shopStore.removeImgCertification}/>

        <div className="w-100 pb-4 border-bottom mt-8 mb-8">
          <strong><h4>Cơ sở sản xuất</h4></strong>
        </div>

        <div className='table-responsive'>
          {shopStore.dataRequest.shopCompany.factory && shopStore.dataRequest.shopCompany.factory.length > 0 ?
            <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
              <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th>Ảnh</th>
                <th>Tên cơ sở</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {shopStore.dataRequest.shopCompany.factory.map((item: any, i: number) => {
                return (
                  <tr key={i}>
                    <td>
                      <div className="symbol symbol-30px symbol-md-40px">
                        <img src={item.image} alt=""/>
                      </div>
                    </td>
                    <td>{item.name}</td>
                    <td width="10%">
                      <button className="btn btn-icon btn-danger btn-sm" onClick={() => shopStore.dataRequest.shopCompany.factory.splice(i, 1)}><i className="fas fa-trash-alt"/></button>
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </table> : <NoContent/>}
        </div>

        <button className="btn btn-md btn-info mt-5 mb-5" onClick={() => shopStore.clearFormFactory()} data-bs-toggle='modal' data-bs-target='#addFactory'>+ Add</button>


        <div className="w-100 pb-4 border-bottom mt-4 mb-8">
          <strong><h4>Thương hiệu</h4></strong>
        </div>
        <div className="mb-5">
          <label className="form-label">Tầm nhìn</label>
          <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.brand.vision} onChange={(e: any) => shopStore.dataRequest.shopCompany.brand.vision = e.currentTarget.value} placeholder="Nhập tầm nhìn"/>
        </div>
        <div className="mb-5">
          <label className="form-label">Sứ mệnh</label>
          <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.brand.mission} onChange={(e: any) => shopStore.dataRequest.shopCompany.brand.mission = e.currentTarget.value} placeholder="Nhập sứ mệnh"/>
        </div>
        <div className="mb-5">
          <label className="form-label">Về chúng tôi</label>
          <textarea className="form-control" rows={4} value={shopStore.dataRequest.shopCompany.brand.aboutUs} onChange={(e: any) => shopStore.dataRequest.shopCompany.brand.aboutUs = e.currentTarget.value} placeholder="Nhập về chúng tôi"/>
        </div>
        <div className="w-100 pb-4 border-bottom mt-8 mb-8">
          <strong><h4>Liên hệ</h4></strong>
        </div>
        <div className='row'>
          <div className='col-6'>
            <div className="mb-5">
              <label className="form-label">Tên đại diện</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.contact.representer} onChange={(e: any) => shopStore.dataRequest.shopCompany.contact.representer = e.currentTarget.value} placeholder="Nhập tên đại diện"/>
            </div>
            <div className="mb-5">
              <label className="form-label">Số điện thoại</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.contact.phone} onChange={(e: any) => shopStore.dataRequest.shopCompany.contact.phone = e.currentTarget.value} placeholder="Nhập số điện thoại"/>
            </div>
          </div>
          <div className='col-6'>
            <div className="mb-5">
              <label className="form-label">Email</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.contact.email} onChange={(e: any) => shopStore.dataRequest.shopCompany.contact.email = e.currentTarget.value} placeholder="Nhập email"/>
            </div>
            <div className="mb-5">
              <label className="form-label">Địa chỉ</label>
              <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.contact.address} onChange={(e: any) => shopStore.dataRequest.shopCompany.contact.address = e.currentTarget.value} placeholder="Nhập địa chỉ"/>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <label className="form-label">Trang web</label>
          <input type="text" className="form-control" value={shopStore.dataRequest.shopCompany.contact.website} onChange={(e: any) => shopStore.dataRequest.shopCompany.contact.website = e.currentTarget.value} placeholder="Nhập link trang mạng"/>
        </div>

        <AddFactory/>
        <button type='submit' className='btn btn-primary' onClick={() => shopStore.update()} disabled={shopStore.isLoadingBt}>
          {shopStore.isLoadingBt ? (
            <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                </span>
          ) : 'Lưu'}
        </button>
      </div>
    </div>
  )
})

export default EditShop