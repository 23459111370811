import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import Loading from '../../common/components/Loading'
import NoContent from '../../common/components/NoContent'
import {productStore} from './ProductStore'
import Paginate from '../../common/components/Paginate'
import {number_format, slug} from '../../common/utils/Utils'
import {Link} from 'react-router-dom'
import DeleteProduct from './components/DeleteProduct'

const ProductWrapper = observer(() => {


  const handlePageClick = async (data: any) => {
    productStore.page = data.selected;
    await productStore.getProducts();
  };


  useEffect(() => {
     productStore.getProducts();
  }, [])

  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-10'>
        <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Danh sách sản phẩm</h1>
        <Link to={`/add-product`} className='btn btn-sm btn-primary' onClick={() => productStore.clearForm()}>Tạo mới</Link>
      </div>

      {productStore.isLoading ? <Loading/> : <div className='table-responsive'>
        {productStore.listProduct && productStore.listProduct.length > 0 ?
          <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
            <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
              <th>ID</th>
              <th>Ảnh sản phẩm</th>
              <th>Tên sản phẩm</th>
              <th>Shop ID</th>
              <th>Danh mục</th>
              <th>Giá</th>
              <th>Trạng thái</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {productStore.listProduct.map((item: any, i: number) => {
              return (
                <tr key={i}>
                  <td>{item.id}</td>
                  <td>
                    {item.images && <div className="symbol symbol-30px symbol-md-40px">
                      <img src={item.images[0]} alt=""/>
                    </div>}
                  </td>
                  <td>{item.name}</td>
                  <td>{item.shopId}</td>
                  <td>{item.category?.name ?? ''} </td>
                  <td>{number_format(item.salePrice)} đ</td>
                  <td>{item.state}</td>
                  <td width="10%">
                    <Link to={`/edit-product/${slug(item.name)}.${item.id}.html`} className="btn btn-warning btn-icon btn-sm" title="Sửa" onClick={() => productStore.getProductDetail(item.id)}><i className="fas fa-pen"/></Link>
                    <span className="p-2"/>
                    <a href="#" className="btn btn-icon btn-danger btn-sm" data-bs-toggle='modal' data-bs-target='#delete_product' onClick={() => productStore.id = item.id}><i className="fas fa-trash-alt"/></a>
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table> : <NoContent/>}
      </div>}
      <DeleteProduct/>
      <Paginate currentPage={productStore.page} totalPage={productStore.totalPage} callback={handlePageClick}/>

    </>
  )
})

export default ProductWrapper