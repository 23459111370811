import axios, {Method} from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import StorageService from "../service/StorageService";
import HttpStatusCode from "../constants/HttpErrorCode";
import Constants from "../constants/Constants";

export interface IApiResponse {
    readonly status: number;
    readonly body: any;
}

export interface IBodyError {
    readonly errorCode: number;
    readonly message: string
}

// @ts-ignore
const refreshAuthLogic = failedRequest => axios.post(Constants.API_URL + '/v1/auth/refreshToken', {'refreshToken': StorageService.getRefreshToken()}).then(tokenRefreshResponse => {
    StorageService.setToken(tokenRefreshResponse.data.refreshToken);
    failedRequest.response.config.headers[Constants.TOKEN_NAME] = tokenRefreshResponse.data.refreshToken;
    return Promise.resolve();

}).catch(function (error) {
    StorageService.removeToken();
    StorageService.removeRefreshToken()
    window.location.href = "/"
    return Promise.reject();
});

let apiUrl = process.env.REACT_APP_API_URL

export async function getRequest(path: string): Promise<IApiResponse> {
    var newHeaders: any;

    if (StorageService.isTokenExits()) {
        newHeaders = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + StorageService.getToken()
        }
        createAuthRefreshInterceptor(axios, refreshAuthLogic, {
            pauseInstanceWhileRefreshing: true
        });
    }else {
        newHeaders = {'Content-Type': 'application/json'}
    }

    return await axios.get(apiUrl + path, {headers: newHeaders})
        .then(
            (response) => {

                const apiResponse: IApiResponse = {
                    status: response.status,
                    body: response.data,
                };
                return apiResponse;
            },
            (error) => {
                if (error.response && error.response.status === HttpStatusCode.UNAUTHORIZED || error.response?.data?.message == "Invalid authentication 4") {
                    // toastUtil.error('Token expire');
                    StorageService.removeToken();
                }

                let bodyError: IBodyError;
                try {
                    bodyError = {
                        errorCode: error.response.data?.errorCode,
                        message: error.response.data?.message
                    }
                } catch (e) {
                    bodyError = {
                        errorCode: HttpStatusCode.UNKNOW_ERROR,
                        message: "Unknow error, please try again later"
                    }
                }

                const apiResponse: IApiResponse = {
                    status: error.response?.status,
                    body: bodyError
                };
                return apiResponse;
            }
        );
}

export async function postRequest(path: string, params: object): Promise<IApiResponse> {
    return apiCall(path, "POST", params);
}

export function apiCall(path: string, _method: Method = "POST", _params: object): Promise<IApiResponse> {
    var newHeaders: any;

    if (StorageService.isTokenExits()) {
        newHeaders = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + StorageService.getToken()
        }
        createAuthRefreshInterceptor(axios, refreshAuthLogic, {
            pauseInstanceWhileRefreshing: true
        });
    }else {
        newHeaders = {'Content-Type': 'application/json'}
    }

    return new Promise<IApiResponse>((resolve) => {
        axios({
            data: JSON.stringify(_params),
            headers: newHeaders,
            method: _method,
            url: apiUrl + path
        })
            .then(function (response) {
                resolve({
                    status: response.status,
                    body: response.data,
                });
            })
            .catch(function (error) {
            if (error.response && error.response.status === HttpStatusCode.UNAUTHORIZED || error.response.data.message == "Invalid authentication 4") {
                // toastUtil.error('Token expire');
                StorageService.removeToken();
                // window.location.href = "/"
            }

            let bodyError: IBodyError;
            try {
                if (error.response && error.response.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                    bodyError = {
                        errorCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
                        message: "Internal server error, please try again later"
                    }
                } else {
                    bodyError = {
                        errorCode: error.response.data.errorCode,
                        message: error.response.data.message
                    }
                }

            } catch (e) {
                bodyError = {
                    errorCode: HttpStatusCode.UNKNOW_ERROR,
                    message: "Unknow error, please try again later"
                }
            }

            const apiResponse: IApiResponse = {
                status: error.response.status,
                body: bodyError
            };

            resolve(apiResponse);
        });

    });
}

export async function putRequest(path: string, params: object): Promise<IApiResponse> {
    return apiCall(path, "PUT", params);
}

export async function deleteRequest(path: string, params: object): Promise<IApiResponse> {

    return apiCall(path, "DELETE", params);
}
