import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Loading from "../../../common/components/Loading";
import NoContent from "../../../common/components/NoContent";
import AddFooter from "./components/AddFooter";
import { footerStore } from "./FooterStore";
import DeleteFooter from "./components/DeleteFooter";
import EditFooter from "./components/EditFooter";

const FooterWrapper = observer(() => {

  useEffect(() => {
    footerStore.getFooter()
  }, [])

  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-10'>
        <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Danh sách Footer</h1>
        <a href='#' className='btn btn-sm btn-primary' onClick={() => footerStore.clearForm()} data-bs-toggle='modal' data-bs-target='#add_config_footer'>Tạo mới</a>
      </div>
      {footerStore.isLoading ? <Loading/> : <div className='table-responsive'>
        {footerStore.listFooter && footerStore.listFooter.length > 0 ? <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
          <thead>
          <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
            <th>ID</th>
            <th>Tiêu đề</th>
            <th/>
          </tr>
          </thead>
          <tbody>
          {footerStore.listFooter.map((item: any, i: number) => {
            return (
              <tr key={i}>
                <td>{item.id}</td>
                <td>{item.title}</td>
                <td width="10%">
                  <a href="#" className="btn btn-icon btn-warning btn-sm" onClick={() => footerStore.getDetail(item.id)} data-bs-toggle='modal' data-bs-target='#edit_config_footer'><i className="fas fa-pen"/></a>
                  <span className="p-2"/>
                  <a href="#" className="btn btn-icon btn-danger btn-sm" data-bs-toggle='modal' data-bs-target='#delete_footer' onClick={() => footerStore.id = item.id}><i className="fas fa-trash-alt"/></a>
                </td>
              </tr>
            )
          })}
          </tbody>
        </table> : <NoContent/>}
        <AddFooter/>
        <EditFooter/>
        <DeleteFooter/>
      </div>}
    </>
  )
})

export default FooterWrapper