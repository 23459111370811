import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {observer} from 'mobx-react-lite'
import {productStore} from '../ProductStore'

const DeleteProduct = observer(() => {

  return (
    <div className='modal fade' id='delete_product' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-500px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Bạn có chắc muốn xóa ?</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' id="close_delete" data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-footer d-flex justify-content-end py-6 px-9'>
            <button type="reset" className="btn btn-light btn-active-light-primary me-2" data-bs-dismiss='modal'>Hủy</button>
            <button type='submit' className='btn btn-primary' onClick={() => productStore.delete()} disabled={productStore.isLoadingBt}>
              {productStore.isLoadingBt ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                </span>
              ) : 'Đồng ý'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default DeleteProduct