import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import CategoriesWrapper from '../pages/categories/CategoriesWrapper'
import ShopsWrapper from '../pages/shops/ShopsWrapper'
import ProductWrapper from '../pages/products/ProductWrapper'
import AddProduct from '../pages/products/components/AddProduct'
import EditProduct from '../pages/products/components/EditProduct'
import BannerWrapper from '../pages/config/banner/BannerWrapper'
import OrderWrapper from '../pages/order/OrderWrapper'
import MenuWrapper from '../pages/config/menu/MenuWrapper'
import AddShop from '../pages/shops/components/AddShop'
import EditShop from '../pages/shops/components/EditShop'
import FooterWrapper from "../pages/config/footer/FooterWrapper";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='categories' element={<CategoriesWrapper />} />
        <Route path='products' element={<ProductWrapper />} />
        <Route path='add-product' element={<AddProduct />} />
        <Route path='edit-product/:slug.:id.html' element={<EditProduct />} />
        <Route path='shops' element={<ShopsWrapper />} />
        <Route path='add-shop' element={<AddShop />} />
        <Route path='edit-shop/:slug.:id.html' element={<EditShop />} />
        <Route path='banner' element={<BannerWrapper />} />
        <Route path='order' element={<OrderWrapper />} />
        <Route path='menu' element={<MenuWrapper />} />
        <Route path='footer' element={<FooterWrapper />} />


        <Route path='/profile/*' element={<SuspensedView><ProfilePage /></SuspensedView>} />
        <Route path='/account' element={<SuspensedView><AccountPage /></SuspensedView>} />
        <Route path='/user-management' element={<SuspensedView><UsersPage /></SuspensedView>} />
        {/* Page Not Found */}

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  
  // @ts-ignore
  return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
