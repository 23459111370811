import React, {useEffect} from 'react'
import {productStore} from '../ProductStore'
import {categoryStore} from '../../categories/CategoryStore'
import {observer} from 'mobx-react-lite'
import {numberUtil} from '../../../common/utils/NumberUtil'
import {shopStore} from '../../shops/ShopStore'
import UploadMultiImg from '../../../common/components/UploadMultiImg'

const AddProduct = observer(() => {


  useEffect(() => {
    shopStore.getShops(2000)
    categoryStore.searchLevel = 2
    categoryStore.getCate(2000)
    productStore.getUnit()
    return () => {
      categoryStore.searchLevel = ''
      productStore.clearForm()
    }
  }, [])


  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-5'>
        <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Tạo mới sản phẩm</h1>
      </div>
      <div className='content'>
        <div className='row'>
          <div className='col-6'>
            <div className='mb-5'>
              <div className='mb-5'>
                <label className='form-label'>Chọn cửa hàng</label>
                <select className='form-select' value={productStore.shopId}
                        onChange={(e: any) => productStore.shopId = e.currentTarget.value}>
                  <option>Chọn cửa hàng</option>
                  {shopStore.listShop.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>{item.name}</option>
                    )
                  })}
                </select>
              </div>
              <label className='form-label'>Tên sản phẩm</label>
              <input type='text' className='form-control' value={productStore.dataRequest.name}
                     onChange={(e: any) => productStore.dataRequest.name = e.currentTarget.value}
                     placeholder='Nhập tên sản phẩm' />
            </div>
            <div className='mb-5 row'>
              <div className='col-6'>
                <label className='form-label'>Số lượng</label>
                <input type='text' className='form-control' value={productStore.dataRequest.quantity}
                       onChange={(e: any) => productStore.dataRequest.quantity = numberUtil.regexNumber(e)}
                       placeholder='Nhập số lượng' />
              </div>
              <div className='col-6'>
                <label className='form-label'>Cân nặng</label>
                <input type='text' className='form-control' value={productStore.dataRequest.weight}
                       onChange={(e: any) => productStore.dataRequest.weight = numberUtil.regexNumber(e)}
                       placeholder='Nhập cân nặng' />
              </div>
            </div>
            <div className='mb-5'>
              <label className='form-label'>Kích thước đóng gói</label>
              <div className='row'>
                <div className='col-4'>
                  <input type='text' className='form-control' value={productStore.dataRequest.packingSize[0]} onChange={(e: any) => productStore.dataRequest.packingSize[0] = numberUtil.regexNumber(e)} placeholder='Chiều dài' />
                </div>
                <div className='col-4'>
                  <input type='text' className='form-control' value={productStore.dataRequest.packingSize[1]} onChange={(e: any) => productStore.dataRequest.packingSize[1] = numberUtil.regexNumber(e)} placeholder='Chiều rộng' />
                </div>
                <div className='col-4'>
                  <input type='text' className='form-control' value={productStore.dataRequest.packingSize[2]} onChange={(e: any) => productStore.dataRequest.packingSize[2] = numberUtil.regexNumber(e)} placeholder='Chiều cao' />
                </div>
              </div>
            </div>
            <div className='mb-5 row'>
              <div className='col-6'>
                <label className='form-label'>Giá gốc</label>
                <input type='text' className='form-control' value={productStore.dataRequest.price}
                       onChange={(e: any) => productStore.dataRequest.price = numberUtil.regexNumber(e)}
                       placeholder='Nhập giá gốc' />
              </div>
              <div className='col-6'>
                <label className='form-label'>Giá bán</label>
                <input type='text' className='form-control' value={productStore.dataRequest.salePrice}
                       onChange={(e: any) => productStore.dataRequest.salePrice = numberUtil.regexNumber(e)}
                       placeholder='Nhập giá bán' />
              </div>
            </div>
            <div className='mb-5'>
              <label className='form-label'>Sku</label>
              <input type='text' className='form-control' value={productStore.dataRequest.sku}
                     onChange={(e: any) => productStore.dataRequest.sku = e.currentTarget.value}
                     placeholder='Nhập Sku' />
            </div>
            <div className='mb-5'>
              <label className='form-label'>Đơn vị</label>
              <select className='form-select' value={productStore.dataRequest.unitId}
                      onChange={(e: any) => productStore.dataRequest.unitId = e.currentTarget.value}>
                <option>Chọn đơn vị</option>
                {productStore.listUnit.map((item, i) => {
                  return (
                    <option key={i} value={item.id}>{item.name}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='col-6'>
            <div className='mb-5'>
              <label className='form-label'>Ảnh</label>
              <UploadMultiImg listImg={productStore.dataRequest.images} changeImg={productStore.uploadImg} removeImg={productStore.removeImg}/>
            </div>
            <div className='mb-5'>
              <label className='form-label'>Danh mục</label>
              <select className='form-select' value={productStore.dataRequest.categoryId}
                      onChange={(e: any) => productStore.dataRequest.categoryId = e.currentTarget.value}>
                <option>Chọn danh mục</option>
                {categoryStore.listCate.map((item, i) => {
                  return (
                    <option key={i} value={item.id}>{item.name}</option>
                  )
                })}
              </select>
            </div>
            <div className='mb-5'>
              <label className='form-label'>Địa chỉ nguồn gốc</label>
              <input type='text' className='form-control' value={productStore.dataRequest.originSourceLink}
                     onChange={(e: any) => productStore.dataRequest.originSourceLink = e.currentTarget.value}
                     placeholder='Nhập link nguồn gốc' />
            </div>
            <div className='mb-5'>
              <label className='form-label'>Mô tả</label>
              <textarea className='form-control' rows={9} value={productStore.dataRequest.description}
                        onChange={(e: any) => productStore.dataRequest.description = e.currentTarget.value}
                        placeholder='Nhập mô tả' />
            </div>
          </div>
        </div>
        <div className='d-flex mt-5 align-items-center justify-content-end'>
          <button type='submit' className='btn btn-primary' onClick={() => productStore.addProduct()} disabled={productStore.isLoadingBt}>
            {productStore.isLoadingBt ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
            ) : 'Lưu'}
          </button>
        </div>
      </div>
    </>
  )

})

export default AddProduct