import {makeAutoObservable} from 'mobx'
import {categoryService} from './CategoryService'
import HttpStatusCode from '../../common/constants/HttpErrorCode'
import $ from "jquery"


export enum IState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED'
}

class CategoryStore{
  id: any = ''
  page: any = 0
  file: any = ''
  searchLevel: any = ''
  totalPage: any = 0
  listCate: any[] = []
  listCateParent: any[] = []
  dataRequest: any = {
    id: '',
    name: '',
    sort: '',
    state: '',
    parentId: '',
    image: ''
  }
  isLoadingUpload: boolean = false
  isLoading: boolean = false
  isLoadingBt: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  clearForm(){
    this.file = ''
    this.dataRequest = {
      id: '',
      name: '',
      sort: '',
      state: '',
      parentId: '',
      image: ''
    }
  }


  async getCate(size?: any){
    this.isLoading = true;
    const result = await categoryService.getCate(size)
    this.isLoading = false;
    if(result.status === HttpStatusCode.OK){
      this.listCate = result.body.data;
      this.totalPage = result.body.metadata.totalPage
    }
  }

  async getCateParent(){
    const result = await categoryService.getCateParent()
    if(result.status === HttpStatusCode.OK){
      this.listCateParent = result.body.data;
    }
  }

  async changeImg(value: any) {
    categoryStore.dataRequest.image = value
  }

  async getCateDetail(id: any) {
    const result = await categoryService.getDetailCate(id);
    if (result.status === HttpStatusCode.OK) {
      this.dataRequest = result.body;
    }
  }

  async add() {

    let {name, sort, image, parentId} = this.dataRequest

    let data: any = {
      name: name,
      sort: sort,
      image: image,
      parentId: parentId
    }

    this.isLoadingBt = true;
    const result = await categoryService.addCate(data);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      // toastUtil.success('Add category success')
      alert('Add category success')
      $('#close_add').trigger('click')
      this.clearForm()
      await this.getCate()
    }
    // else toastUtil.error(result.body.message)
  }

  async update() {
    let {id, name, sort, image, parentId, state} = this.dataRequest

    let data: any = {
      name: name,
      sort: sort,
      image: image,
      state: state,
      parentId: parentId
    }


    this.isLoadingBt = true;
    const result = await categoryService.updateCate(id, data);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      // toastUtil.success('Update category success');
      alert('Update category success')
      $('#close_edit').trigger('click')
      this.listCate.map((item) => {
        if (item.id === id) {
          item.sort = sort
          item.name = name
          item.state = state
          item.image = image
          item.parentId = parentId
        }
      })
      this.clearForm()
    }
    // else toastUtil.error(result.body.message)
  }

  async delete() {
    this.isLoadingBt = true;
    const result = await categoryService.deleteCate(this.id);
    this.isLoadingBt = false;
    if (result.status === HttpStatusCode.OK) {
      // toastUtil.success('Delete category success')
      $('#close_delete').trigger('click')
      this.getCate()
    }
    // else toastUtil.error(result.body.message)
  }

}

export const categoryStore = new CategoryStore();