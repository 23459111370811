import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {observer} from 'mobx-react-lite'
import {numberUtil} from '../../../../common/utils/NumberUtil'
import UploadImg from '../../../../common/components/UploadImg'
import {bannerStore} from '../BannerStore'

const AddOrUpdate = observer(() => {

  return (
    <div className='modal fade' id='add_or_update' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-1000px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>{bannerStore.title} banner</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' id='close_modal' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            <div className='row mb-5'>
              <div className='col-6'>
                <label className='form-label w-100'>Ảnh web</label>
                <UploadImg src={bannerStore.dataRequest.image} changeImg={bannerStore.changeImg} />
              </div>
              <div className='col-6'>
                <label className='form-label w-100'>Ảnh mobile</label>
                <UploadImg src={bannerStore.dataRequest.imageMobile} changeImg={bannerStore.changeImgMb} />
              </div>
            </div>
            <div className='mb-5'>
              <label className='form-label'>Vị trí</label>
              <input type='text' className='form-control' value={bannerStore.dataRequest.sort}
                     onChange={(e: any) => bannerStore.dataRequest.sort = numberUtil.regexNumber(e)}
                     placeholder='Nhập vị trí' />
            </div>
            <div className='mb-5'>
              <label className='form-label'>Liên kết đến</label>
              <input type='text' className='form-control' value={bannerStore.dataRequest.linkTo}
                     onChange={(e: any) => bannerStore.dataRequest.linkTo = e.currentTarget.value}
                     placeholder='Nhâp liên kết đến' />
            </div>
            <div className='mb-5'>
              <label className='form-label'>Nội dung</label>
              <input type='text' className='form-control' value={bannerStore.dataRequest.content}
                     onChange={(e: any) => bannerStore.dataRequest.content = e.currentTarget.value}
                     placeholder='Nhập nội dung' />
            </div>
          </div>

          <div className='modal-footer d-flex justify-content-end py-6 px-9'>
            <button type='reset' className='btn btn-light btn-active-light-primary me-2'
                    data-bs-dismiss='modal'>Hủy
            </button>
            <button type='submit' className='btn btn-primary' onClick={() => bannerStore.addOrUpdate()}
                    disabled={bannerStore.isLoadingBt}>
              {bannerStore.isLoadingBt ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              ) : 'Lưu'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )

})

export default AddOrUpdate