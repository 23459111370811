import {makeAutoObservable} from 'mobx'
import HttpStatusCode from '../../common/constants/HttpErrorCode'
import $ from 'jquery'
import {shopService} from './ShopService'

interface IFactory {
  name: string,
  image: any
}

class ShopStore {
  page: any = 0
  totalPage: any = 0
  listShop: any[] = []
  listProvince: any[] = []
  listDistrict: any[] = []
  dataFactory: IFactory = {
    name: '',
    image: ''
  }

  dataRequest: any = {
    id: '',
    name: '',
    description: '',
    avatar: '',
    banner: '',
    website: '',
    provinceId: '',
    districtId: '',
    shopCompany: {
      description: "",
      brand: {
        aboutUs: "",
        vision: "",
        mission: ""
      },
      contact: {
        representer: "",
        phone: "",
        email: "",
        address: "",
        website: ""
      },
      productionCapacity: {
        factoryArea: ""
      },
      unitInformation: {
        representer: "",
        phone: "",
        email: "",
        address: "",
        website: "",
        business: "",
        mainProduct: "",
        revenue: "",
        numberOfEmployees: "",
        foundedYear: ""
      },
      certification: [],
      factory: []
    }
  }
  isLoading: boolean = false
  isLoadingBt: boolean = false


  constructor() {
    makeAutoObservable(this)
  }

  clearFormFactory(){
    this.dataFactory = {
      name: '',
      image: ''
    }
  }

  clearForm() {
    this.dataRequest = {
      id: '',
      name: '',
      description: '',
      avatar: '',
      banner: '',
      website: '',
      provinceId: '',
      districtId: '',
      shopCompany: {
        description: "",
        brand: {
          aboutUs: "",
          vision: "",
          mission: ""
        },
        contact: {
          representer: "",
          phone: "",
          email: "",
          address: "",
          website: ""
        },
        productionCapacity: {
          factoryArea: ""
        },
        unitInformation: {
          representer: "",
          phone: "",
          email: "",
          address: "",
          website: "",
          business: "",
          mainProduct: "",
          revenue: "",
          numberOfEmployees: "",
          foundedYear: ""
        },
        certification: [],
        factory: []
      }
    }
  }

  async changeImg(value: any) {
    shopStore.dataFactory.image = value
  }


  async getShops(size?: any) {
    this.isLoading = true
    const result = await shopService.getShops(size)
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      this.listShop = result.body.data
      this.totalPage = result.body.metadata.totalPage
    }
  }

  async getProvinces() {
    const result = await shopService.getProvinces()
    if (result.status === HttpStatusCode.OK) {
      this.listProvince = result.body
    }
  }

  async getDistricts(provinceId: any) {
    const result = await shopService.getDistricts(provinceId)
    if (result.status === HttpStatusCode.OK) {
      this.listDistrict = result.body
    }
  }

  async getShopDetail(id: any) {
    this.isLoading = true
    const result = await shopService.getDetailShop(id)
    this.isLoading = false
    if (result.status === HttpStatusCode.OK) {
      this.dataRequest = result.body
      if(this.dataRequest.provinceId){
       await this.getDistricts(this.dataRequest.provinceId)
      }
    }
  }

  async changeAvatar(value: any) {
    shopStore.dataRequest.avatar = value
  }

  async changeBanner(value: any) {
    shopStore.dataRequest.banner = value
  }


  async uploadImgCertification(value: any) {
    shopStore.dataRequest.shopCompany.certification.push(value)
  }

  async removeImgCertification(i: any) {
    shopStore.dataRequest.shopCompany.certification.splice(i, 1)
  }

  async changeProvince(provinceId: any) {
    this.dataRequest.provinceId = provinceId
    await this.getDistricts(provinceId)
  }

  async add() {
    let {name, shopCompany, avatar, banner, website, provinceId, districtId} = this.dataRequest

    let data: any = {
      name: name,
      avatar: avatar,
      banner: banner,
      website: website,
      description: shopCompany.description,
      provinceId: provinceId,
      districtId: districtId,
      shopCompany: {
        description: shopCompany.description,
        brand: {
          aboutUs: shopCompany.brand.aboutUs,
          vision: shopCompany.brand.vision,
          mission: shopCompany.brand.mission
        },
        contact: {
          representer: shopCompany.contact.representer,
          phone: shopCompany.contact.phone,
          email: shopCompany.contact.email,
          address: shopCompany.contact.address,
          website: shopCompany.contact.website
        },
        productionCapacity: {
          factoryArea: shopCompany.productionCapacity.factoryArea
        },
        unitInformation: {
          representer: shopCompany.unitInformation.representer,
          phone: shopCompany.unitInformation.phone,
          email: shopCompany.unitInformation.phone,
          address: shopCompany.unitInformation.address,
          website: shopCompany.unitInformation.website,
          business: shopCompany.unitInformation.business,
          mainProduct: shopCompany.unitInformation.mainProduct,
          revenue: shopCompany.unitInformation.revenue,
          numberOfEmployees: shopCompany.unitInformation.numberOfEmployees,
          foundedYear: shopCompany.unitInformation.foundedYear
        },
        certification: shopCompany.certification,
        factory: shopCompany.factory
      }
    }

    if (!data.name) {
      // toastUtil.warning('Trường name không được để trống', 1)
      return
    }

    this.isLoadingBt = true
    const result = await shopService.addShop(data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      // toastUtil.success('Add shop success')
      alert('Add shop success')
      this.clearForm()

    }
    // else toastUtil.error(result.body.message)
  }

  async update() {
    let {id, name, shopCompany, avatar, banner, website, provinceId, districtId} = this.dataRequest

    let data: any = {
      name: name,
      avatar: avatar,
      banner: banner,
      website: website,
      description: shopCompany.description,
      provinceId: provinceId,
      districtId: districtId,
      shopCompany: {
        description: shopCompany.description,
        brand: {
          aboutUs: shopCompany.brand.aboutUs,
          vision: shopCompany.brand.vision,
          mission: shopCompany.brand.mission
        },
        contact: {
          representer: shopCompany.contact.representer,
          phone: shopCompany.contact.phone,
          email: shopCompany.contact.email,
          address: shopCompany.contact.address,
          website: shopCompany.contact.website
        },
        productionCapacity: {
          factoryArea: shopCompany.productionCapacity.factoryArea
        },
        unitInformation: {
          representer: shopCompany.unitInformation.representer,
          phone: shopCompany.unitInformation.phone,
          email: shopCompany.unitInformation.email,
          address: shopCompany.unitInformation.address,
          website: shopCompany.unitInformation.website,
          business: shopCompany.unitInformation.business,
          mainProduct: shopCompany.unitInformation.mainProduct,
          revenue: shopCompany.unitInformation.revenue,
          numberOfEmployees: shopCompany.unitInformation.numberOfEmployees,
          foundedYear: shopCompany.unitInformation.foundedYear
        },
        certification: shopCompany.certification,
        factory: shopCompany.factory
      }
    }

    if (!data.name) {
      // toastUtil.warning('Trường name không được để trống', 1)
      return
    }


    this.isLoadingBt = true
    const result = await shopService.updateShop(id, data)
    this.isLoadingBt = false
    if (result.status === HttpStatusCode.OK) {
      // toastUtil.success('Update shop success');
      alert('Update shop success')
    }
    // else toastUtil.error(result.body.message)
  }


  async addFactory(){

    let data = {
      name: this.dataFactory.name,
      image: this.dataFactory.image
    }
    if(!data.image){
      alert('Vui lòng chọn ảnh')
      return
    }

    if(!data.name){
      alert('Tên cơ sở không được để trống')
      return
    }

    if(!this.dataRequest.shopCompany.factory){
      this.dataRequest.shopCompany.factory = []
    }

    this.dataRequest.shopCompany.factory.push(data)
    $('#close_add').trigger('click')
    this.clearFormFactory()
  }


}

export const shopStore = new ShopStore()