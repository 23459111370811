import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {shopStore} from './ShopStore'
import NoContent from '../../common/components/NoContent'
import Paginate from '../../common/components/Paginate'
import Loading from '../../common/components/Loading'
import {Link} from 'react-router-dom'
import {slug} from '../../common/utils/Utils'


const ShopsWrapper = observer(() => {

  const handlePageClick = async (data: any) => {
    shopStore.page = data.selected;
    await shopStore.getShops();
  };

  useEffect(() => {
    shopStore.getShops()
  }, [])

  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-10'>
        <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Danh sách cửa hàng</h1>
        <Link to={'/add-shop'} className='btn btn-sm btn-primary' onClick={() => shopStore.clearForm()}>Tạo mới</Link>
      </div>

      {shopStore.isLoading ? <Loading/> : <div className='table-responsive'>
        {shopStore.listShop && shopStore.listShop.length > 0 ?
          <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
            <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
              <th>ID</th>
              <th>Ảnh</th>
              <th>Tên cửa hàng</th>
              <th>Mô tả</th>
              <th>Trạng thái</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {shopStore.listShop && shopStore.listShop.map((item: any, i: number) => {
              return (
                <tr key={i}>
                  <td>{item.id}</td>
                  <td>
                    <div className="symbol symbol-30px symbol-md-40px">
                      <img src={item.avatar} alt=""/>
                    </div>
                  </td>
                  <td>{item.name}</td>
                  <td style={{maxWidth: 500, overflow: 'hidden'}}>{item.description}</td>
                  <td>{item.state}</td>
                  <td width="10%">
                    <Link to={`/edit-shop/${slug(item.name)}.${item.id}.html`} className="btn btn-warning btn-icon btn-sm" title="Cập nhập cửa hàng"><i className="fas fa-pen"/></Link>
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table> : <NoContent/>}
      </div>}

      <Paginate currentPage={shopStore.page} totalPage={shopStore.totalPage} callback={handlePageClick}/>

    </>
  )
})

export default ShopsWrapper