import {makeAutoObservable} from 'mobx'
import {getRequest} from '../../common/helpers/RequestHelper'
import HttpStatusCode from '../../common/constants/HttpErrorCode'

class OrderStore {
  page: number = 0
  totalPage: number = 0
  isLoading: boolean = false
  listOrder: any[] = []
  listProduct: any[] = []

  constructor() {
    makeAutoObservable(this)
  }

  async getOrder(){
    this.isLoading = true
    const result = await getRequest(`/v1/cms/orders/request?page=${this.page}&size=10`)
    this.isLoading = false
    if(result.status === HttpStatusCode.OK){
      this.listOrder = result.body.data;
      this.totalPage = result.body.metadata.totalPage
    }
  }


}

export const orderStore = new OrderStore()