import {IApiResponse, getRequest, postRequest, putRequest} from '../../common/helpers/RequestHelper'
import {shopStore} from './ShopStore'
import {profileStore} from '../../modules/profile/ProfileStore'

class ShopService {

  public getShops(size?: any): Promise<IApiResponse>{
    return getRequest(`/v1/shops?page=${shopStore.page}&size=${size ?? '10'}`)
  }

  public getDetailShop(id: any): Promise<IApiResponse>{
    return getRequest(`/v1/shops/${id}`)
  }

  public addShop( data: any ): Promise<IApiResponse>{
    return postRequest(`/v1/cms/shops/user/${profileStore.profileDetail.id}`, data)
  }

  public updateShop(id: any, data: any): Promise<IApiResponse>{
    return putRequest(`/v1/cms/shops/${id}`, data)
  }

  public getProvinces(): Promise<IApiResponse>{
    return getRequest(`/v1/resource/provinces`)
  }

  public getDistricts(provinceId: any): Promise<IApiResponse>{
    return getRequest(`/v1/resource/districts?provinceId=${provinceId}`)
  }

}

export const shopService = new ShopService()